import * as Sentry from '@sentry/react';
import { useState, useEffect, useContext } from 'react';
import { Cart, Group } from '@shopify/app-bridge/actions';
import { Loading, Banner, Card, ResourceList, SkeletonThumbnail, Thumbnail, Text } from '@shopify/polaris';
import Api from '../helpers/api';
import { Inertia } from '@inertiajs/inertia';

import { CartContext } from '../context-providers/CartContext';

export function CartDisplay() {
    const [loading, setLoading] = useState(false);
    const [statusTitle, setStatusTitle] = useState('Initialising...');
    const [statusKind, setStatusKind] = useState('');

    const [productsData, setProductsData] = useState({});
    const [loadedProducts, setLoadedProducts] = useState({});

    const cartContext = useContext(CartContext);

    useEffect(() => {
        setLoading(true);
        setStatusTitle('Checking cart status...');
    }, []);

    useEffect(() => {
        if (cartContext.cartStatus == 'available') {
            setStatusTitle('Loading cart data...');
            setStatusKind('');
        } else if (cartContext.cartStatus == 'unavailable') {
            setStatusTitle('Cart is not available. Please try again shortly.');
            setStatusKind('critical');
            setLoading(false);
        } else if (cartContext.cartStatus == 'access-error') {
            setStatusTitle('There was an error while fetching the cart. Please try again shortly.');
            setStatusKind('critical');
            setLoading(false);
        } else if (cartContext.cartStatus == 'ready') {
            setStatusTitle('Fetching product info...');
            setStatusKind('');
        }
    }, [cartContext.cartStatus]);

    // Fetch extended product data from the store, for displaying line items
    useEffect(() => {
        if (cartContext.engravingChargeProduct == null) {
            return;
        }
        const fetchLineItemData = async () => {
            if (cartContext.lineItems?.length) {
                let anyItemsHaveData = false;
                setLoadedProducts({});

                cartContext.lineItems.forEach((lineItem) => {
                    if (lineItem.productId == cartContext.engravingChargeProduct.id) {
                        return;
                    }

                    if (lineItem.productId && productsData[lineItem.productId] == undefined) {
                        // Custom line items don't have a productId!
                        setLoadedProducts((existing) => ({
                            ...existing,
                            [lineItem.productId]: false || loadedProducts[lineItem.productId],
                        }));

                        anyItemsHaveData = true;

                        Api.getProduct(lineItem.productId)
                            .then((response) => {
                                let productEntry = {};
                                productEntry[lineItem.productId] = response.data;
                                setProductsData((existing) => ({
                                    ...existing,
                                    [lineItem.productId]: response.data,
                                }));
                            })
                            .catch((error) => {
                                Sentry.captureException(error);
                            })
                            .finally(() => {
                                setLoadedProducts((existing) => ({
                                    ...existing,
                                    [lineItem.productId]: true,
                                }));
                            });
                    }
                });

                if (anyItemsHaveData == false) {
                    setLoading(false);
                }
            } else if (cartContext.lineItems && cartContext.lineItems.length == 0) {
                setLoading(false);
            }
        };
        fetchLineItemData().catch(Sentry.captureException);
    }, [cartContext.lineItems, cartContext.engravingChargeProduct]);

    useEffect(() => {
        if (
            Object.values(loadedProducts).length > 0 &&
            Object.values(loadedProducts).length == Object.values(loadedProducts).filter((val) => val).length
        ) {
            setLoading(false);
        }
    }, [loadedProducts]);

    return (
        <>
            {loading && <Loading />}
            {(loading || cartContext.cartStatus == 'unavailable') && <Banner title={statusTitle} status={statusKind} />}

            {cartContext?.lineItems &&
                cartContext.lineItems.filter(
                    (item) => item.productId && item.productId != cartContext.engravingChargeProduct.id,
                ).length == 0 && <Banner title="No engravable items." status="warning" />}

            {cartContext?.lineItems &&
                cartContext.lineItems.filter(
                    (item) => item.productId && item.productId != cartContext.engravingChargeProduct.id,
                ).length > 0 &&
                productsData && (
                    <Card>
                        <ResourceList
                            resourceName={{ singular: 'product', plural: 'products' }}
                            items={cartContext?.lineItems.filter(
                                (item) => item.productId && item.productId != cartContext.engravingChargeProduct.id,
                            )}
                            renderItem={(item, index) => {
                                const { productId, title, sku, quantity } = item;

                                let media = productsData[productId]?.image?.src ? (
                                    <Thumbnail source={productsData[productId].image.src} size="medium" />
                                ) : (
                                    <SkeletonThumbnail size="medium" />
                                );

                                return (
                                    <ResourceList.Item
                                        id={productId}
                                        onClick={() => {
                                            productsData[productId]?.supportsEngraving
                                                ? Inertia.visit(`/customize/${index}/${productId}`)
                                                : () => {};
                                        }}
                                        media={media}
                                        accessibilityLabel={`Add engraving for ${title}`}
                                    >
                                        <Text
                                            variant="bodyMd"
                                            fontWeight={productsData[productId]?.supportsEngraving ? 'bold' : ''}
                                            as="h3"
                                            color={productsData[productId]?.supportsEngraving ? '' : 'subdued'}
                                        >
                                            {title}
                                        </Text>

                                        {loadedProducts[productId] !== undefined && !loadedProducts[productId] && (
                                            <Text color="subdued">Loading...</Text>
                                        )}

                                        {!productsData[productId]?.supportsEngraving && (
                                            <Text color="warning">Engraving not supported.</Text>
                                        )}

                                        <div>SKU: {sku}</div>
                                        <div>{quantity} in cart</div>

                                        {item.properties && (
                                            <div>
                                                {item.properties.map((property) => (
                                                    <div>
                                                        <b style={{ display: 'block' }}>{property.name}:</b>
                                                        {property.value.startsWith('https://') &&
                                                        (property.value.includes('.png') ||
                                                            property.value.includes('.jpg')) ? (
                                                            <img src={property.value} style={{ maxWidth: '100%' }} />
                                                        ) : (
                                                            property.value
                                                        )}
                                                    </div>
                                                ))}
                                            </div>
                                        )}
                                    </ResourceList.Item>
                                );
                            }}
                        />
                    </Card>
                )}
        </>
    );
}
