import { getSessionToken } from '@shopify/app-bridge-utils';
import { createApp } from '@shopify/app-bridge';
import Axios from 'axios';

Axios.interceptors.request.use(
    async (config) => {
        const app = createApp(window.appBridgeConfig);
        const token = document.querySelector('meta[name=bypass-jwt]') ? 'god' : await getSessionToken(app);

        return {
            ...config,
            headers: {
                ...config?.headers,
                'x-session': document.querySelector('meta[name=session-id]')?.content,
                'x-token': token,
            },
        };
    },
    (error) => alert(error.message) || Promise.reject(error),
);
