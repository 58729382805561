// Ref: https://github.com/riessgroup/nusmile-integration/blob/master/resources/js/helpers/api.js
import { getSessionToken } from '@shopify/app-bridge-utils';
import axios from 'axios';

const client = axios.create();

client.interceptors.request.use(async (config) => {
    const token = await getSessionToken(window.app);
    config.headers.Authorization = `Bearer ${token}`;

    return config;
});

const Api = {
    getProduct: async (id) => {
        return client.get(`/ajax/products/${id}`);
    },
    getEngravingChargeProduct: async () => {
        return client.get(`/ajax/engraving-charge-product`);
    },
    uploadProductImage: async (image_data_url) => {
        return client.post('/ajax/upload-image', { foo: 'bar', image_data_url: image_data_url });
    },
};

export default Api;
