import React from 'react';
import { Frame, Page } from '@shopify/polaris';

import { CartDisplay } from '../components/CartDisplay';

import { CartContextProvider } from '../context-providers/CartContext';

export default function ({ gitCommit, isProduction }) {
    const envInfo = isProduction ? '' : ' - ' + gitCommit.substr(0, 6);
    return (
        <Frame>
            <div style={{ padding: '0 2rem' }}>
                <Page title={`Add Engraving ${envInfo}`}>
                    <CartContextProvider>
                        <CartDisplay />
                    </CartContextProvider>
                </Page>
            </div>
        </Frame>
    );
}
