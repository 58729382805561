import React, { useContext, useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import { Inertia } from '@inertiajs/inertia';
import { Cart } from '@shopify/app-bridge/actions';
import { DescriptionList, LegacyStack, Modal, Spinner, TextContainer, Text } from '@shopify/polaris';

import Api from '../helpers/api';

import { CartContext } from '../context-providers/CartContext';

// Set the line item properties for the CURRENTLY EDITED line item only.
// e.g. you open the engraving data for lineItemIndex, and that's what comes back here.
export const LineItemPropertiesSetter = ({ lineItemIndex, engravingProperties }) => {
    const personalizationIdPropertyName = 'Personalization ID';

    const cartContext = useContext(CartContext);

    const [showingModal, setShowingModal] = useState(false);
    const [submitting, setSubmitting] = useState(false);

    const hideModal = () => {
        setShowingModal(false);
    };

    const getRemovalPropertyKeys = () => {
        if (cartContext.lineItems[lineItemIndex].properties) {
            return cartContext.lineItems[lineItemIndex].properties.filter((property) => {
                return property.name != personalizationIdPropertyName;
            });
        }
        return [];
    };

    const applyLineItemProperties = (personalizationId, engravingProperties) => {
        let propertiesToStore = { ...engravingProperties };

        propertiesToStore[personalizationIdPropertyName] = personalizationId;

        if (engravingProperties['Preview'] != undefined) {
            Api.uploadProductImage(engravingProperties['Preview']).then((response) => {
                propertiesToStore['Preview'] = response.data.cdn_image_url;
                delete propertiesToStore['preview_image'];

                cartContext.cart.dispatch(Cart.Action.SET_LINE_ITEM_PROPERTIES, {
                    index: lineItemIndex,
                    data: propertiesToStore,
                });

                hideModal();
                setSubmitting(false);
                Inertia.visit('/');
            });
        } else {
            cartContext.cart.dispatch(Cart.Action.SET_LINE_ITEM_PROPERTIES, {
                index: lineItemIndex,
                data: propertiesToStore,
            });
            hideModal();
            setSubmitting(false);
            Inertia.visit('/');
        }
    };

    const submitData = () => {
        // hideModal();
        setSubmitting(true);

        let personalizationId = new Date().getTime() + Math.random(); // a quick way to add a unique ID to this line item.
        // This is only needed the first time we see the item, and we won't remove it ever.

        if (cartContext.lineItems[lineItemIndex].properties) {
            let existingPersonalizationItem = cartContext.lineItems[lineItemIndex].properties.find((property) => {
                return property.name == personalizationIdPropertyName;
            });
            if (existingPersonalizationItem) {
                personalizationId = existingPersonalizationItem.value;
            }

            let removePropertyKeys = getRemovalPropertyKeys();

            cartContext.cart.dispatch(Cart.Action.REMOVE_LINE_ITEM_PROPERTIES, {
                index: lineItemIndex,
                data: removePropertyKeys,
            });

            // If we needed to remove keys first, wait for that before we add the new ones.
            // setTimeout is far from ideal
            // But, it does actually work, unlike setting a useEffect on the cart while waiting for removal
            // Which just gets stuck forever :/

            window.setTimeout(() => {
                applyLineItemProperties(personalizationId, engravingProperties);
            }, 500);
        } else {
            // And apply the ones we received
            applyLineItemProperties(personalizationId, engravingProperties);
        }
    };

    // Show the modal whenever we get new data
    useEffect(() => {
        Sentry.setContext('Engraving properties', { engravingProperties });
        Sentry.captureMessage('Engraving properties updated');
        setShowingModal(Object.keys(engravingProperties).length > 0);
    }, [engravingProperties]);

    return (
        <>
            {showingModal && (
                <Modal
                    open={showingModal}
                    onClose={hideModal}
                    title="Confirm Engraving"
                    primaryAction={{
                        content: 'Confirm',
                        onAction: submitData,
                    }}
                    secondaryActions={[
                        {
                            content: 'Cancel',
                            onAction: hideModal,
                        },
                    ]}
                >
                    <Modal.Section>
                        <TextContainer spacing="loose">
                            <DescriptionList
                                items={Object.entries(engravingProperties)
                                    .filter(([key, value]) => {
                                        return key != 'Preview';
                                    })
                                    .map(([key, value]) => {
                                        return { term: key, description: value };
                                    })}
                            />
                            {submitting && (
                                <LegacyStack vertical={false}>
                                    <Spinner accessibilityLabel="Load spinner" size="small" />
                                    <Text variant="bodyLg" as="p">
                                        Storing personalization data...
                                    </Text>
                                </LegacyStack>
                            )}
                        </TextContainer>
                    </Modal.Section>
                </Modal>
            )}
        </>
    );
};
