import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { Provider } from '@shopify/app-bridge-react';
import { AppProvider } from '@shopify/polaris';
import translations from '@shopify/polaris/locales/en.json';
import { createInertiaApp } from '@inertiajs/inertia-react';
import { InertiaProgress } from '@inertiajs/progress';
import { createApp } from '@shopify/app-bridge';

Sentry.init({
    dsn: import.meta.env.VITE_SENTRY_LARAVEL_DSN,
    environment: import.meta.env.VITE_APP_ENV,
    tracesSampleRate: 1.0,
});

InertiaProgress.init();

import '@shopify/polaris/build/esm/styles.css';
import './bootstrap';

function Pos({ children }) {
    return (
        <Provider config={window.appBridgeConfig}>
            <AppProvider i18n={translations}>{children}</AppProvider>
        </Provider>
    );
}

window.app = createApp(window.appBridgeConfig);

// LOGGER
// Sometimes Sentry doesn't catch react stuff :(
// This puts it in the DOM. Make sure #log is outside of React.
if (false) {
    ['log', 'warn', 'error'].forEach(function (verb) {
        console[verb] = (function (method, verb, log) {
            return function () {
                let argumentsList = [...arguments];
                method(...argumentsList);
                // handle distinguishing between methods any way you'd like
                var msg = document.createElement('code');
                msg.style.display = 'block';
                msg.style.maxWidth = '100vw';
                msg.style.overflowX = 'auto';
                msg.classList.add(verb);
                msg.textContent =
                    verb +
                    ': ' +
                    argumentsList
                        .map((arg) => {
                            if (typeof arg == 'object') {
                                return JSON.stringify(arg);
                            }
                            return '' + arg;
                        })
                        .join(' ');
                document.querySelector('#log').appendChild(msg);
                if (verb == 'error') {
                    Sentry.captureMessage(msg.textContent);
                }
            };
        })(console[verb].bind(console), verb, log);
    });
}
// END LOGGER

createInertiaApp({
    resolve: (name) => {
        const pages = import.meta.glob('./pages/**/*.jsx', { eager: true });
        return pages[`./pages/${name}.jsx`];
    },
    setup({ el, App, props }) {
        ReactDOM.render(
            <React.StrictMode>
                <Pos>
                    <App {...props} />
                </Pos>
                ,
            </React.StrictMode>,
            document.getElementById('app'),
        );
    },
});

document.getElementById('load-info').remove();
